import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigEndpoint } from '@shared/services/config-endpoint-decorator';
import { Observable } from 'rxjs';
import { BasicSchedulerDateRange, BasicSchedulerScheduleDto } from './basic-scheduler.model';
import { BasicSchedulerCloneModalRequest } from './basic-scheduler-clone-modal/basic-scheduler-clone-modal.component';
import { ScheduleInfo } from '@features/schedules/schedules.models';
@Injectable({
  providedIn: 'root'
})
@ConfigEndpoint({ version: 1 })
export class BasicSchedulerHttpService {
  constructor(private http: HttpClient) {}
  private apiUrl: string;

  cloneFromSchedule(scheduleId: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/daily-schedule-info/clone-from-schedule/${scheduleId}`, null);
  }

  getDailyScheduleInfo(): Observable<BasicSchedulerScheduleDto[]> {
    return this.http.get<BasicSchedulerScheduleDto[]>(`${this.apiUrl}/daily-schedule-info`);
  }

  importFile(file: File, dateRange: BasicSchedulerDateRange): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<void>(
      `${this.apiUrl}/daily-schedule/ssim/import?date_from=${dateRange.start_date}&date_to=${dateRange.end_date}`,
      formData
    );
  }

  getDateRangeFromFile(file: File): Observable<BasicSchedulerDateRange> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<BasicSchedulerDateRange>(`${this.apiUrl}/daily-schedule/ssim/metadata`, formData);
  }

  updateDailySchedule(scheduleId: number, name: string): Observable<BasicSchedulerScheduleDto> {
    return this.http.put<BasicSchedulerScheduleDto>(`${this.apiUrl}/daily-schedule-info/${scheduleId}/name`, {
      schedule_name: name
    });
  }

  cloneFromDailySchedule(scheduleId: number, scheduleName: string): Observable<BasicSchedulerScheduleDto> {
    return this.http.post<BasicSchedulerScheduleDto>(
      `${this.apiUrl}/daily-schedule-info/clone-from-daily-schedule/${scheduleId}?scheduleName=${scheduleName}`,
      null
    );
  }

  cloneToSchedules(scheduleId: number, request: BasicSchedulerCloneModalRequest): Observable<ScheduleInfo> {
    return this.http.post<ScheduleInfo>(
      `${this.apiUrl}/daily-schedule-info/${scheduleId}/export-to-optimiser?scheduleName=${encodeURIComponent(request.name)}&fromDate=${encodeURIComponent(request.fromDate)}&toDate=${encodeURIComponent(request.toDate)}`,
      null
    );
  }

  deleteDailySchedule(scheduleId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/daily-schedule-info/${scheduleId}`);
  }
}
